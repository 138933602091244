




































































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { VesselDocumentUpdateModel } from "@/api/generated";
import momentUtility from "@/utility/momentUtility";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselDocumentUpdateDialog extends Validation {
  @Prop(String) private vesselDocumentId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: VesselDocumentUpdateModel = {
    reference: "",
    filename: ""
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    try {
      const response = await Api.VesselDocumentService.apiVesseldocumentVesselDocumentIdGet(
        this.vesselDocumentId
      );
      this.model = response.data;
      if (this.model.startDate) {
        this.model.startDate = momentUtility.pickerFormat(this.model.startDate);
      }
      if (this.model.endDate) {
        this.model.endDate = momentUtility.pickerFormat(this.model.endDate);
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch vessel document");
      this.syncedDialog = false;
    }
  }

  private async onDocumentUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      await Api.VesselDocumentService.apiVesseldocumentVesselDocumentIdPut(
        this.vesselDocumentId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Document updated");
      this.$emit("document-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update document");
    } finally {
      this.syncedDialog = false;
    }
  }
}
