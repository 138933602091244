var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vessel-document-create-dialog"},[_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.syncedDialog),callback:function ($$v) {_vm.syncedDialog=$$v},expression:"syncedDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" New Document ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onCreateDocument($event)}}},[_c('v-card-text',[_c('v-file-input',{staticClass:"base-file-input",attrs:{"topLabel":"Upload Document","placeholder":"Upload Document","solo":""},model:{value:(_vm.model.document),callback:function ($$v) {_vm.$set(_vm.model, "document", $$v)},expression:"model.document"}}),_c('base-input',{attrs:{"topLabel":"Reference","placeholder":"Reference"},model:{value:(_vm.model.reference),callback:function ($$v) {_vm.$set(_vm.model, "reference", $$v)},expression:"model.reference"}}),_c('base-input',{attrs:{"topLabel":"Document Number","placeholder":"Document Number"},model:{value:(_vm.model.documentNumber),callback:function ($$v) {_vm.$set(_vm.model, "documentNumber", $$v)},expression:"model.documentNumber"}}),_c('base-date-picker',{attrs:{"topLabel":"Start Date","placeholder":"Start Date","rules":[
              _vm.dateBefore(
                _vm.model.startDate,
                _vm.model.endDate,
                'Start Date',
                'End Date'
              )
            ]},model:{value:(_vm.model.startDate),callback:function ($$v) {_vm.$set(_vm.model, "startDate", $$v)},expression:"model.startDate"}}),_c('base-date-picker',{attrs:{"topLabel":"End Date","placeholder":"End Date","rules":[
              _vm.dateBefore(
                _vm.model.startDate,
                _vm.model.endDate,
                'Start Date',
                'End Date'
              )
            ]},model:{value:(_vm.model.endDate),callback:function ($$v) {_vm.$set(_vm.model, "endDate", $$v)},expression:"model.endDate"}})],1),_c('v-card-actions',{staticClass:"dialog-actions"},[_c('v-spacer'),_c('base-secondary-btn',{on:{"click":function($event){_vm.syncedDialog = false}}},[_vm._v(" Cancel ")]),_c('base-primary-btn',{attrs:{"type":"submit","loading":_vm.loading}},[_vm._v("Create")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }