




































































import {
  Vue,
  Component,
  Prop,
  PropSync,
  Ref,
  Watch
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import { apiClient } from "@/services/apiService";
import FormDataFactory from "@/utility/formDataFactory";
import SnackbarModule from "@/store/snackbarModule";
import VesselDocumentCreateModel from "@/models/vesselDocumentCreateModel";
import Validation from "@/mixins/validation";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselDocumentCreateDialog extends Validation {
  @Prop(String) private vesselId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: VesselDocumentCreateModel = {
    vesselId: "",
    document: null,
    reference: "",
    filename: ""
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onCreateDocument() {
    if (!this.form.validate()) {
      return;
    }

    this.model.vesselId = this.vesselId;
    this.model.filename = this.model.document?.name ?? "";

    try {
      this.loading = true;
      const formData = FormDataFactory.Create(this.model);
      await apiClient.post("api/vesselDocument", formData);
      snackbarModule.setSnackbarMessage("Vessel Enquiry document created");
      this.$emit("document-created");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to create vessel enquiry document"
      );
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
